import { FilterDTO } from './api/types';

export const DEFAULT_USER_OVERVIEW_SORTING = [
  { fieldName: 'firstName', orderDirection: 'ASC' as const },
  { fieldName: 'lastName', orderDirection: 'ASC' as const },
];

export const USER_OVERVIEW_DEFAULT_FILTERS: FilterDTO[] = [
  {
    fieldName: 'engaged',
    value: 'true',
    negate: false,
    operation: 'in',
    relation: 'AND',
  },
];
