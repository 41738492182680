import React, { useEffect, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Button, Checkbox, Collapse, CollapseProps, Form } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useStore } from 'zustand';
import { FilterList, userOverviewFiltersService, userOverviewStore } from 'entities/userOverview';

import { ReactComponent as BottomArrow } from 'shared/assets/icons/dynamicColor/arrow-bottom.svg';
import { withSuspense } from 'shared/lib/react';
import checkboxStyles from 'shared/styles/ant-custom-components/checkbox-right-label.module.scss';
import collapseStyles from 'shared/styles/ant-custom-components/collapse.module.scss';

import { mapFiltersToFilterDTO } from './lib/mapFiltersToFilterDTO';
import { PanelHeader } from './ui/PanelHeader/PanelHeader';
import { SkeletonLoader } from './ui/SkeletonLoader/SkeletonLoader';

import styles from './UserOverviewFilter.module.scss';

export interface UserOverviewFiltersForm {
  showOnlyNonBillable: boolean;
  availableUsers: boolean;
  filters?: {
    [K in keyof FilterList]: Array<FilterList[K][number]['value']>;
  };
}

const UserOverviewFilter: React.FC = () => {
  const { t } = useTranslation('UserOverview');
  const [searchParams] = useSearchParams();

  const [form] = Form.useForm<UserOverviewFiltersForm>();
  const formFilters = useWatch('filters', form);

  const { data: filters } = useSuspenseQuery(userOverviewFiltersService.queryOptions());

  const fullFilters = useMemo(() => {
    return {
      ...filters,
      engaged: [
        { name: 'Engaged', value: true },
        { name: 'Disengaged', value: false },
      ],
    };
  }, [filters]);

  const { setFilters, resetFilters, resetAll } = useStore(userOverviewStore);

  const onFinish = (values: UserOverviewFiltersForm): void => {
    setFilters({
      filters: mapFiltersToFilterDTO(values.filters),
      availableUsers: values.availableUsers,
      showOnlyNonBillable: values.showOnlyNonBillable,
    });
  };

  const onReset = (): void => {
    form.resetFields();
    resetFilters();
  };

  useEffect(() => {
    if (searchParams.get('availableUsers')) {
      onFinish({
        availableUsers: true,
        showOnlyNonBillable: false,
        filters: {
          engaged: ['true'],
          workProfile: [],
          specialization: [],
          employmentType: [],
          seniority: [],
        },
      });
      form.setFieldsValue({ availableUsers: searchParams.get('availableUsers') === 'true' });
    }
    return () => {
      resetAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll, searchParams]);

  const fitltersKeys = useMemo(() => Object.keys(fullFilters), [fullFilters]);

  const getCollapseIcon: CollapseProps['expandIcon'] = ({ isActive }) => {
    return (
      <span>
        <BottomArrow className={classNames(styles.collapseIcon, { [styles.open]: isActive })} height={10} width={10} />
      </span>
    );
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      className={styles.rootWrapper}
      initialValues={{ filters: { engaged: [true] } }}
    >
      <div className={styles.filtersTitle}>{t('UserOverviewFilter.filters.heading')}</div>
      <div className={styles.filtersBlock}>
        <Form.Item
          name="showOnlyNonBillable"
          valuePropName="checked"
          className={styles.formItemWithoutMargin}
          initialValue={false}
        >
          <Checkbox className={checkboxStyles.checkboxRightLabel}>
            {t('UserOverviewFilter.filters.item.showOnlyNonBillable.text')}
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="availableUsers"
          valuePropName="checked"
          className={styles.formItemWithoutMargin}
          initialValue={false}
        >
          <Checkbox className={checkboxStyles.checkboxRightLabel}>
            {t('UserOverviewFilter.filters.item.availableUsers.text')}
          </Checkbox>
        </Form.Item>
        <Collapse
          ghost
          expandIconPosition="end"
          className={collapseStyles.paddinglessCollapse}
          expandIcon={getCollapseIcon}
          defaultActiveKey={['engaged']}
          items={fitltersKeys.map((key) => {
            const castedKey = key as keyof typeof fullFilters;
            const filterValues = fullFilters[castedKey];
            const count = formFilters?.[castedKey]?.length;
            return {
              key: castedKey,
              label: <PanelHeader count={count ?? 0} headerKey={castedKey} />,
              children: (
                <Form.Item name={['filters', castedKey]}>
                  <Checkbox.Group
                    options={filterValues?.map((filter) => ({ label: filter.name, value: filter.value }))}
                    className={styles.checkboxesWrapper}
                  />
                </Form.Item>
              ),
            };
          })}
        />
      </div>
      <div className={styles.buttonsBlock}>
        <Button type="text" htmlType="reset">
          {t('UserOverviewFilter.filters.buttons.resetButton.text')}
        </Button>
        <Button type="primary" htmlType="submit" className={styles.applyButton}>
          {t('UserOverviewFilter.filters.buttons.applyButton.text')}
        </Button>
      </div>
    </Form>
  );
};

export default withSuspense(UserOverviewFilter, { fallback: <SkeletonLoader /> });
