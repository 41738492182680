import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { PAGE_SIZE } from 'features/UserTable';
import { isHasAccess, pathPermissions } from 'features/validateAccess';

import {
  DEFAULT_USER_OVERVIEW_SORTING,
  USER_OVERVIEW_DEFAULT_FILTERS,
  userOverviewFiltersService,
  userOverviewService,
  userOverviewStore,
} from 'entities/userOverview';

import { pathKeys } from 'shared/lib/react-router';

import UserOverviewPage from './UserOverviewPage';

export const userOverviewPageRoute: RouteObject = {
  path: pathKeys.userOverview(),
  element: createElement(UserOverviewPage),
  loader: async (args) => {
    if (!isHasAccess(pathPermissions.userOverview)) {
      return redirect(pathKeys.dashboard());
    }
    const [, searchParams] = args.request.url.split('?');
    const availableUsers = new URLSearchParams(searchParams).get('availableUsers') === 'true';

    userOverviewStore.getState().setFilters({
      availableUsers,
      showOnlyNonBillable: false,
      filters: USER_OVERVIEW_DEFAULT_FILTERS,
    });

    Promise.all([
      userOverviewFiltersService.prefetchQuery(),
      userOverviewService.prefetchQuery({
        size: PAGE_SIZE,
        filtration: {
          availableUsers,
          showOnlyNonBillable: false,
          orders: DEFAULT_USER_OVERVIEW_SORTING,
          filters: USER_OVERVIEW_DEFAULT_FILTERS,
        },
        searchQuery: '',
      }),
    ]);

    return args;
  },
};
